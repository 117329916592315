import React from 'react'
import ReactDOM from 'react-dom'
import { AppProvider } from './context/AppContext'
import './scss/main.scss'
import Logout from './components/Logout'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { useAppInitializer } from './services/initializer'
import { Initialization } from '@mit/aws-react'
import appConfig from './app-config-global'

const AppLazyLoad = React.lazy(async () => await import('./app').then(module => ({
  default: module.default
})))

const RootComponent: React.FC = () => {
  const { initializeApp } = useAppInitializer()

  return (
    <Router>
      <Switch>
        <Route exact path='/logout' component={Logout} />
        <Route path='/'>
          <Initialization
            App={AppLazyLoad}
            appInitializer={initializeApp}
            appConfigData={appConfig}
            loaderProps={
              {
                backgroundColor: '#07393c',
                contactEmail: 'help@mit.edu',
                name: 'Medical Checkin',
                isLoading: true,
                type: 'Default'
              }
            }
          />
        </Route>
      </Switch>
    </Router>
  )
}

ReactDOM.render(
  <React.StrictMode>
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <AppProvider>
        <RootComponent />
      </AppProvider>
    </MuiPickersUtilsProvider>
  </React.StrictMode>,
  document.getElementById('root')
)
