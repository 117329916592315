const appConfig = {
  "name": "medical-checkin-v2",
  "version": "0.1.0",
  "stage": "prod",
  "deployThisBuild": false,
  "okta": {
    "issuer": "https://mitprod.okta.com/oauth2/aus6sh93rjqnQuszg697",
    "clientId": "4ahs0cd1q8pq77jakpitcn26p1",
    "redirectUris": [
      "https://medicalcheckin-mr.atlas-apps.mit.edu"
    ],
    "postLogoutRedirectUris": [
      "https://medicalcheckin-mr.atlas-apps.mit.edu/logout"
    ],
    "scopes": [
      "address",
      "covid19/checkin",
      "digital-id/search",
      "digital-id/user",
      "email",
      "offline_access",
      "openid",
      "phone",
      "profile"
    ]
  },
  "amplify": {
    "userPoolId": "us-east-1_bCu6BU8d2",
    "userPoolWebClientId": "4ahs0cd1q8pq77jakpitcn26p1",
    "oauth": {
      "domain": "atlas-auth.mit.edu",
      "scope": [
        "covid19/checkin",
        "covid19/impersonate",
        "covid19/user",
        "digital-id/search",
        "digital-id/user",
        "openid",
        "profile"
      ],
      "redirectUris": [
        "https://medicalcheckin-mr.atlas-apps.mit.edu"
      ],
      "postLogoutRedirectUris": [
        "https://medicalcheckin-mr.atlas-apps.mit.edu/logout"
      ],
      "responseType": "token",
      "options": {
        "AdvancedSecurityDataCollectionFlag": false
      }
    }
  }
};
export default appConfig;