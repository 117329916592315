const apiConfigs = {
  "regional": {
    "apiHost": "https://api.mit.edu",
    "paths": {
      "digitalId": "digital-id-v1",
      "medical": "medical-v1",
      "vaccine": "vaccine-v1"
    }
  },
  "global": {
    "apiHost": "https://global.api.mit.edu",
    "paths": {
      "digitalId": "digital-id/v1",
      "medical": "medical/v1",
      "vaccine": "vaccine/v1"
    }
  }
};
export default apiConfigs;