import BaseController from './BaseController'

enum ProfileSource {
  ldap = "ldap",
  mitid = "mitid",
}
export interface UserProfile {
  mitid:	string
  mit_id:	string
  kerberos:	string
  kerberos_id:	string
  krb_name:	string
  first_name:	string
  initials:	string
  last_name:	string
  display_name:	string
  email:	string
  phone_number:	string
  department:	string
  mitHROrganisationId:	string
  mitPreferredMail:	string
  office_location:	string
  title:	string
  dateOfBirth:	string
  mitMoiraStatus:	string
  source:	ProfileSource
}
export interface PersonProfile {
  mitId: string
  kerberosId: string
  firstName: string
  lastName: string
  displayName: string
  email: string
  phone: string
}

export interface Address {
  address1?: string
  address2?: string
  city?: string
  state?: string
  postal_code?: string
}
export interface ExtendedPersonProfile {
  mit_id: string
  krb_name: string
  first_name: string
  last_name: string
  email?: string
  phone?: string
  gender?: string
  date_of_birth?: string
  type?: string
  address: Address
}

export default class PersonApi extends BaseController {
  readonly url_picture: string
  readonly url_profile: string
  readonly url_people: string
  readonly url_person_extended: string

  constructor () {
    super()
    this.url_picture = `${this.apiHost}/${this.apiPathDigitalId}/picture`
    this.url_profile = `${this.apiHost}/${this.apiPathDigitalId}/profile`
    this.url_people = `${this.apiHost}/${this.apiPathDigitalId}/search`
    this.url_person_extended = `${this.apiHost}/${this.apiPathVaccine}/person`
  }

  fetchProfile = async (kerbId?: string): Promise<PersonProfile> => {
    let urlAppend = ''
    if (kerbId && kerbId !== '') { urlAppend = '/' + kerbId }

    const response = await this.useFetch('GET', 'fetchProfile', this.url_profile + urlAppend)
    if (response.status === 200) {
      try {
        const data = await response.json()
        return {
          kerberosId: data.kerberos,
          mitId: data.mitid,
          firstName: data.first_name,
          lastName: data.last_name,
          displayName: data.display_name,
          email: data.email,
          phone: data.phone_number
        }
      } catch (e) {
        return await Promise.reject('person not found')
      }
    } else {
      const text = await response.text()
      return await Promise.reject(text)
    }
  }

  fetchProfileExtended = async (mit_id: string | number): Promise<ExtendedPersonProfile> => {
    const urlAppend = '/' + mit_id

    const response = await this.useFetch('GET', 'fetchProfileExtended', this.url_person_extended + urlAppend)
    if (response.status === 200) {
      const data = await response.json()
      return data
    } else {
      const text = await response.text()
      return await Promise.reject(text)
    }
  }

  fetchPicture = async (mitId?: string): Promise<string> => {
    let urlAppend = ''
    if (mitId && mitId !== '') { urlAppend = '/' + mitId + '?resolution=high' }

    const response = await this.useFetch('GET', 'fetchPicture', this.url_picture + urlAppend)
    if (response.status === 200) {
      const data = await response.blob()
      return URL.createObjectURL(data)
    } else {
      const text = await response.text()
      return await Promise.reject(text)
    }
  }

  fetchProfiles = async (query: string): Promise<PersonProfile[]> => {
    const response = await this.useFetch('GET', 'fetchProfiles', this.url_profile + query)
    if (response.status === 200) {
      const people = await response.json()
      return people.map((person: UserProfile) => {
        return {
          kerberosId: person.kerberos,
          mitId: person.mitid,
          firstName: person.first_name,
          lastName: person.last_name,
          displayName: person.display_name,
          email: person.email,
          phone: person.phone_number
        }
      })
    } else {
      const text = await response.text()
      return await Promise.reject(text)
    }
  }
}
