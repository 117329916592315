import { AuthProvider as AwsAuthProvider } from '@mit/aws-react'
import {BaseController} from './controller'
import appConfig from '../app-config-global'

class AuthProvider {
  controller: any

  constructor () {
    this.controller = new BaseController()
  }

  getToken = async (): Promise<string> => {
    const authorizationToken = await new AwsAuthProvider(
      Object.assign(appConfig)
    ).getToken()

    return authorizationToken
  }

  logout = async (): Promise<any> => {
    await new AwsAuthProvider(Object.assign(appConfig)).signOut()
  }
}

export default AuthProvider
