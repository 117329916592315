import BaseController from './BaseController'

export interface MedicalFluShotResult {
  test_guid: string
  test_date: string
  result: string
}

export interface BodyRegion {
  id: number
  name: string
}

export interface CheckinPerson {
  mit_id: string
  full_name: string
  display_name?: string
}

export default class FluShotsApi extends BaseController {
  readonly url_fetch_results: string
  readonly url_flu_shots_body_regions: string
  readonly url_flu_shots_attestation: string
  readonly url_flu_shots_checkin_person: string
  readonly url_flu_shots_checkin_lots: string

  constructor () {
    super()
    this.url_fetch_results = `${this.apiHost}/${this.apiPathMedical}/tests/results`
    this.url_flu_shots_body_regions = `${this.apiHost}/${this.apiPathMedical}/flu_shots/body_regions/`
    this.url_flu_shots_attestation = `${this.apiHost}/${this.apiPathMedical}/flu_shots/attestation/`
    this.url_flu_shots_checkin_person = `${this.apiHost}/${this.apiPathMedical}/flu_shots/checkin_person/`
    this.url_flu_shots_checkin_lots = `${this.apiHost}/${this.apiPathMedical}/flu_shots/checkin_lots/`
  }

  async fetchFluShotsBodyRegions (): Promise<BodyRegion[]> {
    const response = await this.useFetch('GET', 'fetchFluShotsBodyRegions', this.url_flu_shots_body_regions)
    if (response.status === 200) {
      return response.json()
    } else {
      const text = await response.text()
      return await Promise.reject(text)
    }
  }

  async fetchFluShotsCheckinPersons (): Promise<CheckinPerson[]> {
    const response = await this.useFetch('GET', 'fetchFluShotsCheckinPersons', this.url_flu_shots_checkin_person)
    if (response.status === 200) {
      return response.json()
    } else {
      const text = await response.text()
      return await Promise.reject(text)
    }
  }

  async createFluShotsCheckinPersons (mitId: string): Promise<any> {
    const response = await this.useFetch('POST', 'createFluShotsCheckinPersons', this.url_flu_shots_checkin_person, {
      mit_id: mitId
    })
    if (response.status === 200 || response.status === 201) {
      return response.json()
    } else {
      const text = await response.text()
      return await Promise.reject(text)
    }
  }

  async deleteFluShotsCheckinPersons (mitId: string): Promise<any> {
    const response = await this.useFetch('DELETE', 'deleteFluShotsCheckinPersons', `${this.url_flu_shots_checkin_person}${mitId}`)
    if (response.status === 200 || response.status === 204) {
      return true
    } else {
      const text = await response.text()
      return await Promise.reject(text)
    }
  }

  async fetchFluShotsCheckinLots (): Promise<string[]> {
    const response = await this.useFetch('GET', 'fetchFluShotsCheckinLots', this.url_flu_shots_checkin_lots)
    if (response.status === 200) {
      return response.json()
    } else {
      const text = await response.text()
      return await Promise.reject(text)
    }
  }

  postFluShotsAttestation = async (mitId: string, flu_shot_date: string, body_region: number, location_id: number, personId: string, lotNumber: string): Promise<boolean> => {
    const response = await this.useFetch('POST', 'postFluShotsAttestation', this.url_flu_shots_attestation,
      {
        mit_id: mitId,
        flu_shot_date,
        body_region,
        location_id,
        test_by_mit_id: personId,
        lot_number: lotNumber
      })

    if (response.status !== 200) {
      const text = await response.text()
      return await Promise.reject(text)
    }

    return true
  }
}
