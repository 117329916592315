import apiConfig from '../api-config'
import AuthProvider from '../AuthProvider'

export default class BaseController {
  readonly apiHost: string
  readonly apiPathMedical: string
  readonly apiPathVaccine: string
  readonly apiPathDigitalId: string
  fetchController: any

  constructor () {
    let isGlobalEnabled = localStorage.getItem('isGlobalEnabled') === 'true' ?? false;
    let configKey = (isGlobalEnabled ? 'global': 'regional') as keyof typeof apiConfig;

    this.apiHost = apiConfig[configKey].apiHost ?? ''
    this.apiPathDigitalId = apiConfig[configKey].paths.digitalId ?? ''
    this.apiPathMedical = apiConfig[configKey].paths.medical ?? ''
    this.apiPathVaccine = apiConfig[configKey].paths.vaccine ?? ''
    this.fetchController = {}
  }

  async getHeaders (method: string, file?: boolean, fileName?: any, impersonateKerb?: string): Promise<Headers> {
    const token = await new AuthProvider().getToken()

    const headers: Headers = new Headers({
      Authorization: 'Bearer ' + token
    })

    if (file) {
      headers.append('Content-Type', 'binary/octet-stream')
      headers.append('Content-Filename', fileName)
    } else {
      headers.append('Content-Type', 'application/json')
    }

    if (impersonateKerb) {
      headers.append('X-Impersonated-User', impersonateKerb)
    }
    return headers
  }

  async useFetch (method: string, fetchKey: string, url: string, body?: any, file?: any, fileName?: string | null, impersonateKerb?: string): Promise<any> {
    // if (fetchKey && this.fetchController[fetchKey]) {
    //     this.fetchController[fetchKey].abort();
    // }

    // this.fetchController[fetchKey] = new AbortController();
    // let { signal } = this.fetchController[fetchKey];

    const options: any = {
      method,
      headers: await this.getHeaders(method, file, fileName, impersonateKerb)
    }

    if (body) {
      options.body = file ? body : JSON.stringify(body)
    }

    const response = await fetch(url, options)

    // check for SCP timeout
    const sessionExpiredHeader = response.headers.get('com.sap.cloud.security.login')

    if (sessionExpiredHeader) {
      // show reload dialog
      console.error('SESSION EXPIRED!', 'RELOAD')
      window.location.reload()
    }

    // clear out controller
    this.fetchController[fetchKey] = null

    return response
  }

  async logout (): Promise<any> {
    await new AuthProvider().logout()
  }

  async getUserAuth (): Promise<any> {
    const response = await this.useFetch('GET', 'initialize', `${this.apiHost}/${this.apiPathMedical}/checkin/authorization`)

    if (response.status === 200) {
      const data = response.json()
      return data
    } else {
      const text = await response.text()
      return await Promise.reject(text)
    }
  }

  async getProfile (): Promise<any> {
    const response = await this.useFetch('GET', 'profile', `${this.apiHost}/${this.apiPathDigitalId}/profile`)

    if (response.status === 200) {
      const data = response.json()
      return data
    } else {
      const text = await response.text()
      return await Promise.reject(text)
    }
  }

  async getProfilePicture (): Promise<any> {
    const response = await this.useFetch('GET', 'picture', `${this.apiHost}/${this.apiPathDigitalId}/picture`)

    if (response.status === 200) {
      return response.blob()
    } else {
      const text = await response.text()
      return await Promise.reject(text)
    }
  }

  async getLocations (): Promise<any> {
    const response = await this.useFetch('GET', 'locations', `${this.apiHost}/${this.apiPathMedical}/checkin/locations`)

    if (response.status === 200) {
      const data = response.json()
      return data
    } else {
      const text = await response.text()
      return await Promise.reject(text)
    }
  }
}
