import React, { useState, createContext } from 'react'

import { BodyRegion, CheckinPerson } from '../api/controller/FluShotsApi'
import { Location, LotResponse } from '../api/controller/CheckinApi'
import { PersonProfile } from '../api/controller/PersonApi'
import { Loading } from '../types/Loading'

export const AppContext = createContext({})

interface Props {
  children: React.ReactNode
}

export const AppProvider: React.FC<Props> = ({ children }) => {
  const [auth, setAuth]: any = useState({
    loggedInUser: null /* Username as string */
  })
  const [isLoading, setIsLoading] = useState<Loading>({
    search: false,
    picture: true,
    submit: false,
    appInit: true
  })

  const [scanType, setScanType]: any = useState('medical')
  const [selectedPage, setSelectedPage] = useState<'Vaccine' | 'Flu Shot' | 'COVID Test'>('Vaccine')

  const [selectedPerson, setSelectedPerson] = useState<PersonProfile | null>()

  const [profile, setProfile] = useState<Object>({})
  const [profilePicture, setProfilePicture]: any = useState(null)

  const [userPicture, setUserPicture] = useState<Object>({})
  const [edit, setEdit] = useState<Boolean>(false)
  const [hideEdit, setHideEdit] = useState<Boolean>(false)

  const [selectedLocationLot, setSelectedLocationLot] = useState<number | null>(null)
  const [locationLots, setLocationLots] = useState<string[]>([])

  const [selectedPrinter, setSelectedPrinter] = useState<any | null>(null)
  const [printers, setPrinters] = useState<any>([])

  const [selectedLot, setSelectedLot] = useState<LotResponse | null>(null)

  const [selectedLocation, setSelectedLocation] = useState<Location | null>(null)
  const [locations, setLocations] = useState<Location[]>([])

  const [bodyRegions, setBodyRegions] = useState<BodyRegion[]>([])

  const [selectedCheckinPerson, setSelectedCheckinPerson] = useState<CheckinPerson | null>(null)
  const [checkinPersons, setCheckinPersons] = useState<CheckinPerson[]>([])

  const [userId, setUserId] = useState<any>()
  const [successMessage, setSuccessMessage] = useState<string>()

  const [showEditLots, setShowEditLots] = useState<boolean>(false)
  const [showEditAdministerer, setShowEditAdministerer] = useState<boolean>(false)
  const [showEditLocation, setShowEditLocation] = useState<boolean>(false)

  // const initializeApp = (data: any): void => {
  //   setAuth(data)
  // }

  const initializeProfile = (data: any): void => {
    setProfile(data)
  }

  const initializeProfilePicutre = (data: any): void => {
    const url = URL.createObjectURL(data)
    setProfilePicture(url)
  }

  const exportValue = {
    setAuth,
    // initializeApp,
    initializeProfile,
    initializeProfilePicutre,
    scanType,
    setScanType,
    selectedPage,
    setSelectedPage,
    selectedLocation,
    setSelectedLocation,
    locations,
    setLocations,
    selectedLocationLot,
    setSelectedLocationLot,
    selectedLot,
    setSelectedLot,
    locationLots,
    setLocationLots,
    bodyRegions,
    setBodyRegions,
    selectedCheckinPerson,
    setSelectedCheckinPerson,
    checkinPersons,
    setCheckinPersons,
    auth,
    isLoading,
    setIsLoading,
    selectedPerson,
    setSelectedPerson,
    userPicture,
    setUserPicture,
    edit,
    setEdit,
    profile,
    profilePicture,
    hideEdit,
    setHideEdit,
    userId,
    setUserId,
    successMessage,
    setSuccessMessage,
    selectedPrinter,
    setSelectedPrinter,
    printers,
    setPrinters,
    showEditLots,
    setShowEditLots,
    showEditAdministerer,
    setShowEditAdministerer,
    showEditLocation,
    setShowEditLocation
  }

  return (
    <AppContext.Provider value={exportValue}>
      {children}
    </AppContext.Provider>
  )
}

export const useAppContext = (): any => {
  const context = React.useContext(AppContext)
  if (context === undefined) {
    throw new Error('useAppContext must be used within a AppProvider')
  }
  return context
}
