import { appInitialized } from '@mit/aws-react'
import { useEffect, useState } from 'react'
import { useAppContext } from '../context/AppContext'
import { Loading } from '../types/Loading'

import { BaseController, CheckinApi, FluShotsApi } from '../api/controller'

const baseController = new BaseController()
const checkinApi = new CheckinApi()
const fluShotsApi = new FluShotsApi()

interface UseAppInitializationReturn {
  initializeApp: () => Promise<void>
  initializeApi: boolean
}

export const useAppInitializer = (): UseAppInitializationReturn => {
  const [initializeApi, setInitializeApi] = useState(false)

  const {
    setIsLoading,
    setLocations,
    setCheckinPersons,
    initializeProfile,
    initializeProfilePicutre,
    setBodyRegions,
    setLocationLots
  }: any = useAppContext()

  useEffect(() => {
    appInitialized({
      isLoading: true,
      hasAccess: false,
      hasError: false
    })
  }, [])

  const initializeApp = async (): Promise<void> => {
    let hasError = false

    setIsLoading((state: Loading) => {
      return { ...state, ...{ appInit: true } }
    })
    // Load Profile
    try {
      const [profile, profilePicture] = await Promise.all([
        baseController.getProfile(),
        baseController.getProfilePicture()
      ])
      initializeProfile(profile)
      initializeProfilePicutre(profilePicture)
    } catch (e) {
      console.error(e)
      hasError = true
    }

    // Initialize application/lookups
    try {
      const [
        locationResult,
        bodyRegionResults,
        checkinPersonsResult,
        checkinLotResult
      ] = await Promise.all([
        checkinApi.fetchLocations(),
        fluShotsApi.fetchFluShotsBodyRegions(),
        fluShotsApi.fetchFluShotsCheckinPersons(),
        fluShotsApi.fetchFluShotsCheckinLots()
      ])

      setLocations(locationResult)
      setBodyRegions(bodyRegionResults)
      setLocationLots(checkinLotResult)
      setCheckinPersons(checkinPersonsResult)
    } catch (e) {
      console.error(e)
      hasError = true
    }

    setIsLoading((state: Loading) => {
      return { ...state, ...{ appInit: false } }
    })
    appInitialized({
      isLoading: false,
      hasAccess: true,
      hasError
    })

    setInitializeApi(true)
  }

  return { initializeApi, initializeApp }
}
